





import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Position from '@/store/entities/elections/position';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.position = Util.extend(true, {}, this.$store.state.position.editModel); 
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class PositionForm extends ListBase {
    entity: string = 'position';
    @Prop({ type: Boolean, default: false }) value: boolean;
    position: Position = new Position();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "position",
        title: 'Cargo',
        width: "400px"
    };
    
    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get isEditing() {
        return this.position.id > 0;
    }
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 12, required: true }),
        this.gItem({ type: 'active', size: 12 }),
    ]

    async save() {
        this.$emit('save-success');
        this.cancel();
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
