






















































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Department from '@/store/entities/preferences/department';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';
import ProvinceList from '../province/province.vue';
import DistrictList from '../district/district.vue';

class PageDepartmentRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView, ProvinceList, DistrictList }
})
export default class Departments extends ListBase {
    pagerequest: PageDepartmentRequest = new PageDepartmentRequest();
    department: Department = new Department();
    tabModel: any = null;
    modalSetting: any = {
        entity: "department",
        title: 'Departamento'
    };

    get depaTotalCount() {
        return this.$store.state.department.totalCount;
    };

    get provTotalCount() {
        return this.$store.state.province.totalCount;
    };

    get distTotalCount() {
        return this.$store.state.district.totalCount;
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }  
    items = [
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'code', label: 'Ubigeo', required: true, size: 6 }),
        this.gItem({ type: 'active', size: 6 })
    ]
    columns = [
        this.gCol('country.name', 'Pais', 'text'),
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('code', 'Ubigeo', 'text'),
        this.gCol('active', 'Habilitado'),
        this.defaultActions('edit')
    ]
    async created() {
        //this.getpage();
    }
}