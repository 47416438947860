














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Gender from '@/store/entities/students/gender';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageGenderRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class Genders extends ListBase {
    pagerequest: PageGenderRequest = new PageGenderRequest();
    gender: Gender = new Gender();
    modalSetting: any = {
        entity: "gender",
        title: 'Tratamiento',
        width: "500px"
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }        
    items = [
        this.gItem({ type: 'radio', prop: 'type_id', label: 'Genero', required: true, generalType: 'T_GENDER', size: 4}),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 8 })
    ]
    columns = [
        this.gColO({ type: 'text', value: 'type_id', text: 'Genero', generalType: true }),
        this.gCol('name', 'Nombre', 'text'),
        this.defaultActions()
    ]
    async created() {
        //this.getpage();
    }
}