














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Position from '@/store/entities/elections/position';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';
import PositionForm from './position-form.vue';

class PagePositionRequest extends PageRequest { }

@Component({
    components: { ListView, PositionForm }
})
export default class Positions extends ListBase {
    entity: string = 'position';
    pagerequest: PagePositionRequest = new PagePositionRequest();

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }
    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]

    async created() {
        //this.getpage();
    }
}