


























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import CertificateTemplate from '@/store/entities/courses/certificatetemplate';
import ListBase from '@/lib/listbase';
import EditHtml from './edit-html.vue';

@Component({
    components: { FormBase, VWidget, ModalView, EditHtml },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class CertificateTemplateForm extends ListBase {
    entity: string = 'certificatetemplate';
    @Prop({ type: Boolean, default: false }) value: boolean;
    certificate: CertificateTemplate = new CertificateTemplate();
    modalShow: boolean = false;
    htmlModalShow: boolean = false;
    tabModel: any = null;
    image: any = null;
    urlPhoto: string = url;
    field: string = '';
    modalSetting: any = {
        entity: "certificatetemplate",
        titleFull: 'Plantilla',
        width: '500px',
        showForm: false,
        fullscreen: this.isEditing
    };

    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 4 }),
        this.gItem({ type: 'radio', prop: 'orientation', label: 'Tipo', required: true, size: 4, options: 'orientations', row: true }),
        this.gItem({ type: 'radio', prop: 'nro_pages', label: 'Paginas', required: true, size: 4, options: 'pages_list', row: true }),
    ];
        
    get isEditing() {
        return this.certificate.id > 0;
    }

    get pages() {
        return this.$store.state.certificatetemplate.pages;
    }

    get loading() {
        return this.$store.state[this.entity].loading;
    };

    openHtml(field) {
        this.field = field;
        this.htmlModalShow = !this.htmlModalShow;
    }

    initForm() {
        this.certificate = Util.extend(true, {}, this.$store.state.certificatetemplate.editModel);

        //this.image = null;
        this.setItem(this.items, 'name', 'size', (this.isEditing ? 4 : 12));
        this.setItem(this.items, 'orientation', 'size', (this.isEditing ? 4 : 12));
        this.setItem(this.items, 'nro_pages', 'size', (this.isEditing ? 4 : 12));
        this.modalSetting.fullscreen = this.isEditing;

        if (this.isEditing) {
            this.modalSetting.titleFull = 'Plantilla'
        } else {
            this.modalSetting.titleFull = 'Crear Plantilla';
        }    
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.certificate);
            model.pages = this.pages;

            let newModel = await this.$store.dispatch({
                type: this.entity + '/' + (this.certificate.id > 0 ? 'update' : 'create'),
                data: model
            });

            if (this.certificate.id > 0)
                Util.abp.notify.success(this, 'Plantilla actualizada!');
            else
                Util.abp.notify.success(this, 'La Plantilla ha sido creada!');

            this.$store.commit(this.entity + '/edit', newModel);
            this.initForm();

            this.$emit('save-success');
        }
    }


    cancel() {
        this.$emit('input', false);
    }

    async uploadImage() {
        let model = await this.$store.dispatch({
            type: this.entity + '/uploadPageImage',
            data: {
                id: this.certificate.id,
                number: this.pages[this.tabModel].number,
                page_id: this.pages[this.tabModel].id,
                imageData: this.image,
            }
        });
        (this.$refs.fileInput as any).value = null;

        this.pages[this.tabModel].filename = model.filename;
        this.pages[this.tabModel].image_url = model.image_url;
    }

    openImage() {
        (this.$refs.fileInput as any).click();
    }

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = (e as any).target.result;
                vm.uploadImage();
            };
            reader.readAsDataURL(file);
        }
    }

}
