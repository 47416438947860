






































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import GeneralType from '@/store/entities/preferences/generaltype';
import GeneralTypeValue from '@/store/entities/preferences/generaltypevalue';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageGeneralTypeRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})

export default class GeneralTypes extends ListBase {
    entity: string = 'generaltype';
    pagerequest: PageGeneralTypeRequest = new PageGeneralTypeRequest();
    generaltype: GeneralType = new GeneralType();
    generaltypevalue: GeneralTypeValue = new GeneralTypeValue();
    listTitle: string = 'Valores';
    showNewBtn: boolean = true;
    selected: any = null;
    modalSetting: any = {
        entity: "generaltypevalue",
        title: 'Valor',
        width: "500px"
    };
    
    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', width: 90 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre' }),
        this.gItem({ type: 'switch', prop: 'editable', label: 'Es editable' }),
    ]
    columns = [
        this.gCol('code', 'Codigo', 'text'),
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('editable', 'Es editable', 'boolean'),
    ]

    itemsDetail = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre' }),
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', size: 4 }),
        this.gItem({ type: 'text', prop: 'value', label: 'Valor', size: 4 }),
        this.gItem({ type: 'active', size: 4 }),
    ]        

    get columnsDetail() {
        let columnsDet = [
            this.gCol('code', 'Codigo', 'text'),
            this.gCol('name', 'Nombre', 'text'),
            this.gCol('value', 'Valor', 'text'),                
        ]
        
        if (this.selected != null && this.selected.code.indexOf('E_') >= 0)
            columnsDet.push(this.gColO({ type: 'editColor', value: 'extra', text: 'Color', color: 'extra', save: this.saveColor }));

        columnsDet.push(this.gCol('active', 'Habilitado'));

        if (this.selected != null && this.selected.editable)
            columnsDet.push(this.defaultActions());

        return columnsDet;
    }        

    create() {
        this.modalShow = !this.modalShow;
    }
    edit() {
        this.modalShow = !this.modalShow;
    }
    onRowClick(row: any, index: any) {
        this.listTitle = row.name + ' - ' + this.L('Valores');
        this.$store.commit('generaltypevalue/setList', row.values);
        this.showNewBtn = row.editable;
        this.selected = row;
        //this.columnsDetail = this.columnsDet;

        //if (row.code.indexOf('E_') < 0)
        //    this.columnsDetail.splice(3,1);
        //if (row.editable)
        //    this.columnsDetail = [...this.columnsDet, this.defaultActions()];
    }
    async saveColor(data) {
        await this.$store.dispatch({
            type: 'generaltypevalue/update',
            data: {
                id: data.id,
                extra: data.extra
            }
        });
    }
    getpage() {
        this.listTitle = this.L('Valores');
        this.$store.commit('generaltypevalue/setList', []);
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    async created() {
        //this.getpage();
    }
}