

















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VWidget from '@/components/VWidget.vue';
import ListBase from '@/lib/listbase';
import Util from '@/lib/util'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

@Component({
    components: { VWidget, TiptapVuetify }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {

            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class EditHtml extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Object, default: () => { return {} } }) model: object;
    @Prop({ type: String, default: 'header' }) field: string;
    modalShow: boolean = false;
    property: string = null;
    extensions: Array<any> = [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
            options: {
                levels: [1, 2, 3]
            }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
    ];

    get properties() {
        return this.gCommonList('properties');
    }

    changeProperty(item) {
        if (item != undefined) {
            let code = '{{' + item.id + '}}';
            Util.copyToClipboard(code);
            Util.abp.notify.info(this, 'Campo ' + item.name + ' copiado al portapapeles!');
        }
    }

    //save() {
    //    //this.$emit('save-text' {});
    //    this.$emit('input', false);
    //}

    cancel() {
        this.$emit('input', false);
    }

}
