



















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import VWidget from '@/components/VWidget.vue';
import FormBase from '@/components/helper/form-base.vue';

@Component({
    components: { VWidget, FormBase }
})
export default class CompanyForm extends ListBase {
    entity: string = 'company';
    modalShow: boolean = true;

    get company() {
        return this.$store.state.session.company;
    }

    get loading() {
        return this.$store.state.company.loading;
    }
    
    async save() {
        await this.$store.dispatch({
            type: 'company/update',
            data: this.company
        });
        Util.abp.notify.success(this, 'Guardado Correctamente');

        //this.$store.commit('session/setCompany', this.$store.state.company.editModel);
    }
    
    items = [
        this.gItem({ type: 'object', prop: 'manager_id', label: 'Gerente', entity: 'user', itemText: 'full_name', size: 4 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Ruc', required: true, size: 4 }),
        this.gItem({ type: 'spacer', size: 2 }),
        this.gItem({ type: 'active', size: 2 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Razon Social', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'name_legal', label: 'Razon Comercial', size: 6 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
        this.gItem({ type: 'website', prop: 'website', label: 'Website', size: 6 }),
        this.gItem({ type: 'textarea', prop: 'note', label: 'Notas', hideDetails: true }),
    ]

    itemsAdd = [
        //this.gItem({ type: 'divider', subheader: 'Direccion' }),
        //...this.gAddressItems('')
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', entity: 'country', returnObject: false, size: 3 }),
        this.gItem({ type: 'ubigeo', prop: 'district_id', label: '', entity: 'departament', returnObject: false, size: 9 }),
        this.gItem({ type: 'text', prop: 'address1', label: 'Direccion 1', preicon: 'home', size: 6 }),
        this.gItem({ type: 'text', prop: 'address2', label: 'Direccion 2', preicon: 'home', size: 6 }),
        this.gItem({ type: 'text', prop: 'other', label: 'Otros', size: 3 }),
        this.gItem({ type: 'text', prop: 'postcode', label: 'Codigo Postal', size: 3 }),
        this.gItem({ type: 'phone', prop: 'phone', label: 'Telefono', size: 3 }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Celular', preicon: 'smartphone', size: 3 }),
    ]
    
    mounted() {
        
        //if (this.company.address != null) {
        //    if (this.company.address.country_id != null) this.changeCountry(this.company.address.country_id);
        //    if (this.company.address.department_id != null) this.changeDepartment(this.company.address.department_id);
        //    if (this.company.address.province_id != null) this.changeProvince(this.company.address.province_id);
        //}
        this.modalShow = true;
    }

    async created() {
        
        
    }
}