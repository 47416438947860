














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Country from '@/store/entities/preferences/country';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageCountryRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class Countries extends ListBase {
    entity: string = 'country';
    modalTitle: string = 'Pais';
    pagerequest: PageCountryRequest = new PageCountryRequest();
    country: Country = new Country();
    modalSetting: any = {
        entity: "country",
        title: 'Pais'
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }  
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true }),
        this.gItem({ type: 'text', prop: 'iso_code', label: 'Codigo ISO', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'call_prefix', label: 'Prefijo Telefonico', size: 6 }),
        this.gItem({ type: 'object', prop: 'currency_id', label: 'Moneda por defecto', size: 6 }),
        this.gItem({ type: 'object', prop: 'zone_id', label: 'Zona', generalType: 'T_ZONE', size: 6 }),
        this.gItem({ type: 'switch', prop: 'need_zip_code', label: 'Necesita codigo postal/Zip?', size: 6 }),
        this.gItem({ type: 'active', size: 6 })
    ]

    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('iso_code', 'Codigo ISO', 'text'),
        this.gCol('call_prefix', 'Prefijo Telefonico', 'text'),
        this.gCol('active', 'Habilitado', 'boolean'),
        this.defaultActions('edit')
    ]
    
    async created() {
        //this.getpage();
    }
}