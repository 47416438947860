


































































































































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '../../../components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import DocumentTypes from '@/views/students/documenttype/documenttype.vue';
import Genders from '@/views/students/gender/gender.vue';
import Groups from '@/views/students/group/group.vue';
import Company from '@/views/admin/company/company.vue';
import Countries from '@/views/preferences/country/country.vue';
import Ubigeos from '@/views/preferences/department/department.vue';
import Fees from '@/views/courses/fee/fee.vue';
import CertificateTemplates from '@/views/courses/template/template.vue';
import GeneralTypes from '@/views/preferences/generaltype/generaltype.vue';
import Positions from '@/views/elections/position/position.vue';

@Component({
    components: { VWidget, FormBase, DocumentTypes, Genders, Company, Countries, Ubigeos, Groups, Fees, Positions, GeneralTypes, CertificateTemplates }
})
export default class Departments extends ListBase {
    model: any = {};
    tabModel: any = null;
    tabRecru: any = null;
    tabStude: any = null;
    tabElect: any = null;
    formBase: boolean = false;
    file: null;

    get saving() {
        return this.$store.state.configuration.loading;
    }
    studentItems = [
        //this.gItem({ type: 'object', prop: 'DEFAULT_CUSTOMER', label: 'Usuario por Defecto para ventas', hint: 'Usuario por defecto para realizar ventas rapidas', size: 6, itemText: 'description_full', autocomplete: true, entity: 'student' }),
        //this.gItem({ type: 'text', prop: 'CUSTOMER_CODE_FORMAT', label: 'Formato de Codigo de Usuario', size: 6 }),
        this.gItem({ type: 'object', prop: 'DEFAULT_GROUP', label: 'Grupo de Usuario por Defecto', size: 4, entity: 'group' }),
        this.gItem({ type: 'object', prop: 'DEFAULT_DOCUMENT', label: 'Tipo de Documento por Defecto', size: 4, entity: 'documenttype' }),
        this.gItem({ type: 'object', prop: 'DEFAULT_USER_TYPE', label: 'Tipo de Usuario por Defecto', size: 4, generalType: 'T_STUDENT' }),
        this.gItem({ type: 'email', prop: 'BIENESTAR_EMAIL', label: 'Correo de Bienestar Social', hint: 'Se usara para notificaciones cuando un agremiado actualiza sus datos', persistentHint: true, size: 6, generalType: 'T_STUDENT' }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]
    coursesItems = [
        this.gItem({ type: 'subheader', subheader: 'Links Extrenos' }),
        this.gItem({ type: 'switch', prop: 'SEND_COURSE_EMALS', label: 'Enviar Correos al iniciar matriculas', size: 4 }),
        this.gItem({ type: 'switch', prop: 'SEND_ENROLL_AUTH_EMAIL', label: 'Enviar Correo al aprobar una matricula', size: 4 }),
        this.gItem({ type: 'switch', prop: 'SEND_ATTENDACE_EMALS', label: 'Enviar Correos al iniciar asistencias', size: 4 }),
        this.gItem({ type: 'text', prop: 'URL_ENROLLMENT', label: 'Url para matriculas', preicon: 'http', size: 6 }),
        this.gItem({ type: 'text', prop: 'URL_ATTENDANCE', label: 'Url para marcar asistencias', preicon: 'http', size: 6 }),
        this.gItem({ type: 'divider', subheader: 'Configuracion de Clases' }),
        this.gItem({ type: 'number', prop: 'DEFAULT_CAPACITY', label: 'Nro Maximo de Asistentes', size: 4, prefix: '#' }),
        this.gItem({ type: 'number', prop: 'DEFAULT_MIN_SCORE', label: 'Calificacion Minima', size: 4, prefix: '#', hint: 'Minimo Requerido para aprobar', persistentHint: true }),
        this.gItem({ type: 'number', prop: 'DEFAULT_MIN_ATTENDANCE', label: 'Asistencia Minima', size: 4, suffix: '%', hint: 'Asistencia Minima Requerido para aprobar', persistentHint: true }),
        this.gItem({ type: 'text', prop: 'INVOICE_SERIE', label: 'Serie para Boletas de Pago', size: 2, }),
        this.gItem({ type: 'radio', prop: 'MARKS_NRO', label: 'Modalidad de Marcado de asistencia', size: 6, options: 'mark_types', row: true }),
        this.gItem({ type: 'number', prop: 'MARKS_TIMEOUT', label: 'Tiempo Para Marcar Asistencia', preicon: 'timer', size: 4, suffix: 'Minutos', hint: 'Si coloca 0 el tiempo sera manual', persistentHint: true }),
        this.gItem({ type: 'switch', prop: 'ENABLE_MANUAL_ATTENDACE', label: 'Habilitar Boton Marcar Asistencia Manual', size: 12 }),
    ]
    electionItems = [
        this.gItem({ type: 'subheader', subheader: 'Links Extrenos' }),
        this.gItem({ type: 'switch', prop: 'SEND_VOTING_START_EMAIL', label: 'Enviar Correos al iniciar Votaciones', size: 6 }),
        this.gItem({ type: 'switch', prop: 'SEND_VOTING_RESULT_EMAIL', label: 'Enviar Correo con los resultados de las votaciones', size: 6 }),
        this.gItem({ type: 'text', prop: 'URL_VOTING', label: 'Url para votaciones', preicon: 'http', size: 6 }),
        this.gItem({ type: 'spacer', size: 6 }),

        this.gItem({ type: 'subheader', subheader: 'Valores por Defecto' }),
        this.gItem({ type: 'select', prop: 'VOTER_OPTION_DEFAULT', label: 'Quienes Pueden votar?', size: 4,options:'voter_options' }),
        this.gItem({ type: 'object', prop: 'SECURITY_LEVEL_DEFAULT', label: 'Nivel de Seguridad para Elecciones', generalType: 'SECURITY_LEVEL', size: 4 }),
        this.gItem({ type: 'spacer', size: 4 }),

        this.gItem({ type: 'subheader', subheader: 'Restriccion de Votos' }),
        this.gItem({ type: 'number', prop: 'MAX_CONNECTION_ALLOWED', label: 'Maximo de intentos permitidas luego de votar', size: 3 }),
        this.gItem({ type: 'number', prop: 'MAX_VOTES_ALLOWED', label: 'Maximo de votos permitidas por IP', size: 3 }),
        this.gItem({ type: 'text', prop: 'ALLOWED_IPS', label: 'IP\'s permitidas para Votos Multiples', size: 6,hint:'Separados por comas',persistentHint:true }),
    ]

    culqiItems = [
        this.gItem({ type: 'subheader', subheader: 'Configuracion de Integracion con CULQI' }),
        this.gItem({ type: 'text', prop: 'CULQI_PUBLIC_KEY', label: 'Llave Publica', size: 6 }),
        this.gItem({ type: 'text', prop: 'CULQI_SECRET_KEY', label: 'Llave Secreta', size: 6 }),
    ]
    
    buildModel(data) {
        //...this.generalItems, 
        let items = [...this.studentItems, ...this.coursesItems, ...this.electionItems]
        let model = { };
        items.forEach(item => {
            if (item.prop != undefined && typeof data[item.prop] == "object") {
                var prop = item.prop;
                if (data[item.prop] != undefined) {
                    model[prop] = data[item.prop].id;
                }
            } else if (item.prop != undefined) {
                model[item.prop] = data[item.prop];
            }

        });
        return model;
    }

    async save() {           
        let model = this.buildModel(this.model);
        await this.$store.dispatch({
            type: 'configuration/save',
            data: model
        });
        Util.abp.notify.success(this, 'Los ajustes han sido actualizados correctamente.');
    }

    mounted() {
        this.model = Util.extend(true, {}, Util.abp.setting.values);
    }

    async created() {
        this.formBase = true;
    }
}
