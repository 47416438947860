















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Province from '@/store/entities/preferences/province';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageProvinceRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class Provinces extends ListBase {
    pagerequest: PageProvinceRequest = new PageProvinceRequest();
    province: Province = new Province();
    modalSetting: any = {
        entity: "province",
        title: 'Provincia'
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    } 
    items = [
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', size: 6 }),
        this.gItem({ type: 'object', prop: 'department_id', label: 'Departamento', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'code', label: 'Ubigeo', required: true, size: 6 }),
        this.gItem({ type: 'active', size: 6 })
    ]
    columns = [
        this.gCol('department.name', 'Departamento', 'text'),
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('code', 'Ubigeo', 'text'),
        this.gCol('active', 'Habilitado'),
        this.defaultActions('edit')
    ]
    async created() {
        //this.getpage();
    }
}