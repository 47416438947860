


























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import TemplateForm from './template-form.vue';

class PageCertificateRequest extends PageRequest {
    code: string = '';
    name: string = '';
}

@Component({
    components: { ListView, TemplateForm }
})
export default class Certificates extends ListBase {
    entity: string = 'certificatetemplate';
    pagerequest: PageCertificateRequest = new PageCertificateRequest();
    filterForm: boolean = true;

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    } 

    async afterSave() {
        this.createModalShow = false;
        let list: any = this.$refs.listView
        await list.getpage();
    }
    
    columns = [
        this.gColO({ type: 'text', value: 'name', text: 'Nombre', main: true }),
        this.defaultActions()
    ];

    imageSrc(image) {
        return url + image;
    }

    async created() {
        //this.getpage();            
    }
}