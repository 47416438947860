













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Fee from '@/store/entities/courses/fee';  
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageFeeRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }

})
export default class Fees extends ListBase {
    pagerequest: PageFeeRequest = new PageFeeRequest();
    fee: Fee = new Fee();
    modalSetting: any = {
        entity: "fee",
        title: 'Tarifa',
        width: "700px"
    };

    edit() {
        this.modalShow = true;
    }
    create() {
        this.modalShow = true;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 12, required: true }),
        //this.gItem({ type: 'radio', prop: 'student_type_id', label: 'Tipo de Asistente', generalType: 'T_STUDENT', required: true, size: 12, row: true, defaultValue: '0' }),
        this.gItem({ type: 'price', prop: 'amount', label: 'Precio', size: 4, required: true }),
        this.gItem({ type: 'active', size: 4 }),
        this.gItem({ type: 'spacer', size: 4 }),
    ]

    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('amount', 'Precio', 'price'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]

    async created() {
        //this.getpage();
    }
}